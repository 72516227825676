import React from "react"
import { Container, Typography, Button, Grid } from "@material-ui/core"
import { useButtonStyles } from "../../styles/styles"
import ShadowImage from "../shadowImage"
import { Link } from "gatsby"

export const TextRightImageLeftBlock = ({
  title = "Weekly Events",
  children = `The Main Hall is the largest venue available for weddings,
    auctions, trivia nights and any other large events. Customers,
    including many charities have found a perfect fit with our low
    cost structure and have annually hosted their fund raising efforts
    at our facility.`,
  imageUrl = "https://i.imgur.com/vSAMwKm.png",
  buttonLink,
  buttonText,
  alt
}) => {
  const classes = useButtonStyles()
  const altClass = alt ? "alt" : "";

  return (
    <div className={`text-image-block text-right ${altClass}`}>
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item sm={6} xs={12} className="calendar-column">
            <ShadowImage imageUrl={imageUrl} />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography variant="h4" className="lined-title">
              {title}
            </Typography>

            <Typography
              variant="body1"
              paragraph
              className="mt-2 m-auto weekly-events-block__body"
            >
              {children}
            </Typography>

            {buttonLink && (
              <Link>
                <Button
                  variant="contained"
                  className={classes.primaryButton}
                  style={{ marginTop: 20 }}
                >
                  {buttonText}
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
