import React from "react"
import SEO from "../components/seo"

import { Link } from "gatsby"
import { PAGE_URLS } from "../enum/PageUrls"
import { TextLeftImageRightBlock } from "../components/blocks/TextLeftImageRightBlock"
import { TextRightImageLeftBlock } from "../components/blocks/TextRightImageLeftBlock"
import { CenteredTextBlock } from "../components/blocks/CenteredTextBlock"
import BasicTemplate from "../components/BasicTemplate"

const FacilitiesPage = () => {
  return (
    <BasicTemplate title="Available Facilities">
      <span>
        <SEO
          title="Our Facilities"
          description="We are here to provide for any and all of your needs. We offer Wedding Receptions, Corporate Events, Funeral Services and Catering for Sensitive Occassions, and Holiday Parties."
        />
        <CenteredTextBlock title="What We Have" style={{ paddingBottom: 20 }}>
          We have multiple facilities to help you with your event. See below
          what we have to offer; we are happy to accommodate for any occassion.
        </CenteredTextBlock>

        <TextLeftImageRightBlock
          imageUrl="https://i.imgur.com/GpO1iRK.jpg"
          title="Main Hall"
          altText="Main Hall"
        >
          The KC Hall rents its Main Hall for nearly any occasion. The Main Hall
          is the largest venue and is used by both members and non-members, for
          wedding receptions, anniversaries, trivia nights, class reunions,
          birthday celebrations, corporate or retirement parties, and
          more. This bright and spacious room comfortably seats 240
          guests. From daytime functions to intimate evening affairs, the
          O’Fallon KC Hall is your final stop. <a className="red-link" href={PAGE_URLS.WEDDING_BROCHURE}>See Wedding Brochure</a>
          <br />
          <br />
          The outside attached deck adds an outdoor element for any event to
          enjoy. This (need exact measurements) venue has (measure) ceilings and
          comes complete with a large (need to measure) elevated stage.
          <br />
          <br />
          Our facility accommodates bands, stage acts, and a dance floor to
          enhance any function.
          <br />
          <br />
          Also included is a full service bar area to serve you and your guests
          throughout the event.
        </TextLeftImageRightBlock>

        <TextRightImageLeftBlock
          imageUrl="https://i.imgur.com/hrjlJeF.jpg"
          title="The Lower Hall"
          altText="Lower Hall"
        >
          The Lower Hall located on the lower level of the facility seats comfortably 100
          guests and is perfect for small to medium sized gatherings.
          Anniversary parties, bridal showers, business meetings, birthday parties, garduation parties, and
          company holiday parties are all possible in this comfortable, yet
          spacious venue.
          <br />
          <br />
          If you are a charity and wanting to have a fun fundraising event, ask
          our Manager about “Celebrity Bartender” night where you supply the
          “bartenders/hosts” and collect tips for charity. Invite all your
          friends and guests for a wonderful night of friendship and goodwill
          with all tips at your disposal for your designated charity.
          <br />
          <br />
          This lower level room is adjacent to a large bar area and can be used
          for both catered and non-catered events. This room might be a perfect
          fit for your next gathering.
        </TextRightImageLeftBlock>

        <TextLeftImageRightBlock
          imageUrl="https://i.imgur.com/q3CwcM7.jpg"
          title="Deck"
          altText="Deck"
        >
         The outside deck (directly in the back of the Main Hall) is a great place to grab a bucket and enjoy the outdoors!
        </TextLeftImageRightBlock>

        <TextRightImageLeftBlock
          imageUrl="https://i.imgur.com/vSAMwKm.png"
          title="The Bar"
          altText="The Bar"
        >
          The full-service bar is open to the public from <b>4:00pm</b> to <b>10:00pm</b> every
          day except Mondays with extended hours on the weekends, depending on
          events.
          <br/><br/>
          Our full service bar is open to the public. Come down and enjoy a cold beverage. Whether it be beer or bourbon or anything in between we’ve got you covered.
        </TextRightImageLeftBlock>

        <TextLeftImageRightBlock
          imageUrl="https://i.imgur.com/3xBDSmm.jpg"
          title="Kitchen"
          altText="Kitchen"
        >
          The kitchen menu includes Wednesday wings, Thursday 1/2
          pound burgers, and our signature Friday night fish fry’s, celebrated
          as number one in the area by the readers of the Belleville News
          Democrat. We make fresh made to order pizzas. If you are gluten free,
          or just watching calories the cauliflower crust is a must!
        </TextLeftImageRightBlock>

        <CenteredTextBlock title="Wait! There's More" style={{ paddingBottom: 20 }}>
          See the <Link className="red-link" to={PAGE_URLS.GALLERY}>gallery</Link> to see what we've done in the past!
          We can also cater your event! Contact us or <Link className="red-link" to={PAGE_URLS.GALLERY}>send us an email</Link> to see available options.
        </CenteredTextBlock>
      </span>
    </BasicTemplate>
  )
}

export default FacilitiesPage
